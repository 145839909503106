import { NextSeo } from "next-seo";
import { useRouter } from "next/dist/client/router";
import Image from "next/image";
import Anchor from "../components/utilities/Anchor";
import Typography from "../components/utilities/Typography";
import imageError from "../public/general/404.gif";

const ErrorPage = () => {
  const router = useRouter();

  return (
    <>
      <NextSeo title="404" noindex />

      <div className="bg-white">
        <div className="container relative flex items-center justify-center">
          <Typography
            variant="h1"
            style={{ fontSize: "clamp(60px, 50vw, 500px)" }}
            color="text-primary"
          >
            404
          </Typography>
          <div className="absolute bottom-0 flex max-w-[40vw] lg:max-w-[30vw] xl:max-w-[20vw]">
            <Image src={imageError} width="490" height="476" alt="404 gif" />
          </div>
        </div>
      </div>

      <div className="bg-primary-light">
        <div className="container flex flex-col items-center justify-center space-y-2 py-16 text-center">
          <Typography variant="h2" className="mb-4">
            Het lijkt erop dat je de weg kwijt bent.
          </Typography>

          <Typography variant="h3">
            Geen zorgen, we helpen je graag terug op pad!
          </Typography>

          <ul className="list-none text-lg">
            <li>
              <button
                type="button"
                onClick={() => router.back()}
                className="leading-none text-blue hover:underline focus:underline focus:outline-none motion-safe:transition"
              >
                Ga terug naar de vorige pagina
              </button>
            </li>

            <li>
              <Anchor href="/">Navigeer naar de homepagina</Anchor>
            </li>

            <li>
              <Typography variant="paragraph">
                Of gebruik het menu om te vinden wat je zoekt.
              </Typography>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
